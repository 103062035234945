import {
  dataHistoryTestOptionsSelectorFactory,
  portfolioOptionsSelectorFactory,
  scenarioOptionsSelectorFactory,
  tenantsOptionsSelector,
  testsOptionsSelector,
} from 'modules/options/selectors';
import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.uptime;

export const functionalityTestsCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['functionalityTestsCount'] => uptime.functionalityTestsCount
);

const functionalityTestsHashSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['functionalityTestsHash'] => uptime.functionalityTestsHash
);

export const functionalityTestsSelector = createSelector(
  functionalityTestsHashSelector,
  (functionalityTestsHash: Uptime.Root['functionalityTestsHash']): Uptime.Test[] =>
    Object.values(functionalityTestsHash)
);

export const testOverviewsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviews'] => uptime.testOverviews
);

export const testOverviewsRunConfettiSelector = createSelector(
  testOverviewsSelector,
  (testOverviews: Uptime.Root['testOverviews']): boolean =>
    Boolean(testOverviews?.length) &&
    testOverviews.every(item =>
      Object.values(item)
        .filter(i => typeof i === 'number')
        .every(key => key === 100)
    )
);

export const testOverviewsLastTestRunTimestampSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviewsLastTestRunTimestamp'] => uptime.testOverviewsLastTestRunTimestamp
);

export const testHistoryCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testHistoryCount'] => uptime.testHistoryCount
);

export const testHistoryItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testHistoryItems'] => uptime.testHistoryItems
);

export const statusOverviewsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['statusOverviews'] => uptime.statusOverviews
);

export const dataHistoryCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataHistoryCount'] => uptime.dataHistoryCount
);

export const dataHistoryItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataHistoryItems'] => uptime.dataHistoryItems
);

export const tableDataHistoryWaitForDependenciesSelectorFactory = ({
  tenant,
  portfolio,
  scenario,
}: {
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
  scenario: Layouts.Filters['scenario'];
}) =>
  createSelector(
    [
      dataHistoryTestOptionsSelectorFactory(`${tenant}_${portfolio}_${scenario}`),
      tenantsOptionsSelector,
      portfolioOptionsSelectorFactory(tenant),
      scenarioOptionsSelectorFactory(`${tenant}_${portfolio}`),
    ],
    (dataHistoryTestOptions, tenantsOptions, portfolioOptions, scenarioOptions): boolean => {
      return Boolean(!dataHistoryTestOptions || !tenantsOptions || !portfolioOptions || !scenarioOptions);
    }
  );

export const tableTestHistoryWaitForDependenciesSelectorFactory = ({
  tenant,
  portfolio,
}: {
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
}) =>
  createSelector(
    [
      testsOptionsSelector,
      tenantsOptionsSelector,
      portfolioOptionsSelectorFactory(tenant),
      scenarioOptionsSelectorFactory(`${tenant}_${portfolio}`),
    ],
    (testsOptions, tenantsOptions, portfolioOptions, scenarioOptions): boolean => {
      return Boolean(
        !testsOptions || !tenantsOptions || (tenant && !portfolioOptions) || (tenant && portfolio && !scenarioOptions)
      );
    }
  );
