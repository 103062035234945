import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dataHistoryItemsSelector } from 'modules/uptime/selectors';
import Chart, { getBaseOptions } from '@utiligize/shared/components/Chart';
import { dateFormat } from '@utiligize/shared/utils';

interface ChartDataProps {
  series: any[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartHistory: React.FC = () => {
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const baseOptions = getBaseOptions((str: string) => str, chartData);
  const items = useSelector(dataHistoryItemsSelector);
  const categories = useMemo(() => items?.map(item => dateFormat(item.test_run_timestamp, 'L LT')).reverse(), [items]);

  useEffect(() => {
    if (!items) return;
    setChartData(null);
    setChartData({
      series: [
        {
          name: '',
          type: 'line' as any,
          lineWidth: 3,
          color: 'lightgray',
          data: items.map(item => ({ y: Number(item.value_mean), color: '#ff0000' })).reverse(),
        },
      ],
      title: '',
      xAxisTitle: '',
      yAxisTitle: '',
    });
  }, [items]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
        categories,
        labels: {
          style: {
            whiteSpace: 'nowrap', // Prevent text from wrapping
          },
        },
      },
      yAxis: {
        ...baseOptions.yAxis,
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${this.x}<br><b>Value:</b> ${this.y}`;
        },
      },
      exporting: {
        enabled: false,
      },
      series: chartData?.series,
      legend: { enabled: false },
    }),
    [baseOptions, chartData, categories]
  ) as unknown as Highcharts.Options;

  return <Chart height="20vh" options={chartData ? options : null} />;
};

export default ChartHistory;
