import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers, useAutoFetch } from 'hooks';
import { DataTableTemplate } from '@utiligize/shared/components';
import ModalNewExecution from './ModalNewExecution';
import TableLoadflow from './TableLoadflow';
import { SelectEnvironment, SelectWorkflowTenant } from 'components/_common';
import SelectFlow from './SelectFlow';
import { FetchOptionsPageParam, PaginationType } from 'constants/index';

const ViewLoadflow: React.FC = () => {
  const type = PaginationType.ADMIN_LOADFLOW;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });
  const { nextUpdateDiff, shouldFetch, resetCounter } = useAutoFetch({ fetchInterval: 20000 }); // 20 seconds

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectEnvironment
            name="environment"
            value={filters?.environment || null}
            onChange={handleSelectChange}
            mutedTextLabelKey="Environment"
          />
        </Col>
        <Col xs={2}>
          <SelectFlow
            name="flow"
            value={filters?.flow || null}
            onChange={handleSelectChange}
            mutedTextLabelKey="Flow"
            environment={filters?.environment || null}
            isDisabled={!filters?.environment}
          />
        </Col>
        <Col xs={2}>
          <SelectWorkflowTenant
            name="tenant"
            value={filters?.tenant}
            onChange={handleSelectChange}
            isClearable
            mutedTextLabelKey="Workflow tenant"
            blurInputOnSelect
            environment={filters?.environment || null}
            isDisabled={!filters?.environment}
            page={FetchOptionsPageParam.LOADFLOW}
          />
        </Col>
        <Col>
          <ModalNewExecution />
        </Col>
        <Col xs="auto" className="d-flex ml-auto">
          <small className="text-muted">
            Next update in{' '}
            <span style={{ width: '15px', display: 'inline-block', textAlign: 'center' }} className="text-bold">
              {nextUpdateDiff}
            </span>{' '}
            seconds
          </small>
        </Col>
      </Row>
      <TableLoadflow shouldFetch={shouldFetch} resetCounter={resetCounter} />
    </DataTableTemplate>
  );
};

export default ViewLoadflow;
